@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
/* ProductGrid.css */

.sofadi-one-regular {
    font-family: "Sofadi One", system-ui;
    font-weight: 400;
    font-style: normal;
}


.product-name {
    font-weight: bold;
    color: #333;
    text-align: center;
    margin-top: 10px;
    font-family: Sofadi One, sans-serif; /* Apply the custom font */
}

.product-description {
    color: #777;
    text-align: center;
    margin-top: 5px;
    font-family: "Pacifico", sans-serif; /* Apply the custom font */
}

.animated-card {
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    border-radius: 10px;
    overflow: hidden;
    animation: fadeIn 0.5s ease-in-out;
}

.animated-card:hover {
    transform: scale(1.05);
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.2);
}

.card-media {
    border-bottom: 1px solid #ddd;
}

.card-content {
    padding: 15px;
    background-color: #f9f9f9;
    font-family: sofadi-one-regular, sans-serif; /* Apply the custom font */
}

.heading-box {
    text-align: center;
    margin-bottom: 20px;
    padding: 10px;
    background-color: #f5f5f5;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    font-family: "Sofadi One", sans-serif; /* Apply the custom font */
}

.heading-text {
    font-weight: bold;
    color: #333;
    font-family: "Sofadi One", sans-serif; /* Apply the custom font */
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
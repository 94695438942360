/* AboutUs.css */

body, .Typography, .header, .section, .paper {
    font-family: 'Sofadi One', sans-serif;
}

.container {
    padding: 4rem;
    background-color: #f5f5f5;
}

.header {
    text-align: center;
    margin-bottom: 2rem;
    font-family: 'Sofadi One', sans-serif;
    font-weight: 700;
    color: #333;
}

.section {
    margin-bottom: 2rem;
    font-family: 'Sofadi One', sans-serif;
    font-weight: 400;
}

.paper {
    padding: 2rem;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.team-member {
    text-align: center;
}

.team-member img {
    width: 100%;
    border-radius: 50%;
    margin-bottom: 1rem;
}

.team-member h3 {
    margin-bottom: 0.5rem;
    color: #333;
}

.team-member p {
    color: #777;
}
.container {
    margin-top: 20px;
}

.header {
    font-family: 'Sofadi One', sans-serif;
    text-align: center;
    margin-bottom: 20px;
}

.section {
    margin-top: 20px;
}

.paper {
    padding: 20px;
    text-align: center;
}
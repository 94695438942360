.container {
    margin-top: 20px;
}

.header {
    text-align: center;
    margin-bottom: 20px;
}

.section {
    margin-top: 20px;
}

.paper {
    padding: 20px;
}

.map-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%; /* 16:9 Aspect Ratio */
}

.map-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

.paper {
    padding: 20px;
    text-align: center;
}

.paper .MuiTypography-h6 {
    font-size: 3rem; /* Large size for emojis */
}
.equal-height {
    display: flex;
    flex-wrap: wrap;
}

.equal-height > .MuiGrid-item {
    display: flex;
    flex-direction: column;
}

.equal-height > .MuiGrid-item > .MuiPaper-root {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.paper .MuiTypography-h6 {
    font-size: 3rem; /* Large size for emojis */
}
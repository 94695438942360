.blinking-text {
    font-family: 'Sofadi One', sans-serif;
    font-size: 2.5rem !important; /* Double the font size */
    animation: blink 1s step-start infinite;
}

.blinking-text-mob {
    font-family: 'Sofadi One', sans-serif;
    font-size: 1.5rem !important; /* Double the font size */
    animation: blink 1s step-start infinite;
}

@keyframes blink {
    0% { color: red; }
    25% { color: blue; }
    50% { color: green; }
    75% { color: orange; }
    100% { color: red; }
}